<template>
  <v-app>
    <vue-particles
      color="#240b36"
      :particleOpacity="0.7"
      :particlesNumber="50"
      shapeType="star"
      :particleSize="4"
      linesColor="#c31432"
      :linesWidth="2"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles>
    <v-container class="container">
      <v-card class="grid" width="95%" :elevation="11">
        <v-form ref="form" v-model="valid" :lazy-validation="lazy">
          <v-text-field
            v-model="car.category"
            readonly
            required
            solo
            outlined
            chips
            dense
            dark
          ></v-text-field>

          <v-text-field
            v-model="car.make"
            readonly
            required
            solo
            outlined
            chips
            dense
            dark
          ></v-text-field>

          <v-text-field
            v-model="car.model"
            readonly
            required
            solo
            outlined
            chips
            dense
            dark
          ></v-text-field>

          <v-text-field
            v-model="car.year"
            readonly
            required
            solo
            outlined
            chips
            dense
            dark
          ></v-text-field>

          <v-text-field
            v-model="car.color"
            required
            solo
            outlined
            chips
            dense
            dark
          ></v-text-field>

          <v-text-field
            v-model="car.plateNumber"
            :rules="globalRules"
            required
            solo
            outlined
            chips
            dense
            dark
          ></v-text-field>

          <v-text-field
            v-model="car.pricePerDay"
            :rules="globalRules"
            required
            solo
            outlined
            chips
            dense
            dark
          ></v-text-field>

          <v-text-field
            v-model="car.pricePer3days"
            :rules="globalRules"
            required
            solo
            outlined
            chips
            dense
            dark
          ></v-text-field>

          <v-text-field
            v-model="car.pricePerWeek"
            :rules="globalRules"
            required
            solo
            outlined
            chips
            dense
            dark
          ></v-text-field>

          <v-text-field
            v-model="car.desc"
            :rules="globalRules"
            label="Description"
            required
            solo
            outlined
            dense
            dark
          ></v-text-field>

          <v-col :cols="12" md="4">
            <v-card-text class="text">Update car images</v-card-text>
            <v-file-input
              style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
              prepend-icon="mdi-image-outline"
              accept="image/*"
              @change="uploadImage"
          /></v-col>
          <div>
            <v-row>
              <v-col v-for="url in car.imageUrl" :key="url.id" align="center">
                <v-card class="grid" width="300px" height="300px">
                  <v-row
                    justify="center"
                    align="center"
                    style="height: 61px !important"
                  >
                    <v-btn
                      class="rounded-btn"
                      color="red"
                      @click="removeUrl(url)"
                    >
                      x
                    </v-btn>
                  </v-row>
                  <a
                    :href="url.url"
                    download="car-image.jpg"
                  >
                    <v-img
                      :src="url.url"
                      max-height="250px"
                      max-width="250px"
                      contain
                    >
                    </v-img> </a
                ></v-card>
              </v-col>
            </v-row>
          </div>

          <v-row class="justify-center align-center" no-gutters>
            <v-btn :disabled="!valid" dark class="button" @click="update">
              Update
            </v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import DataService from "../services/dataService.js";
const dataService = new DataService();
export default {
  data() {
    return {
      valid: true,
      globalRules: [(v) => !!v || "Field is required"],
      car: [
        {
          color: "",
          plateNumber: "",
          pricePerDay: "",
          pricePer3days: "",
          pricePerWeek: "",
          desc: "",
          imageUrl: [],
        },
      ],
      lazy: false,
    };
  },

  mounted: function () {
    this.getCar();
  },

  methods: {
    async getCar() {
      if (this.$store.state.user.roles[0] == "admin") {
        this.$setLoader();
        await dataService
          .getCar({ _id: this.$route.params.cardId })
          .then((res) => {
            this.car = res.data.car[0];
          });

        this.$disableLoader();
      } else {
        alert("USER NOT AUTHORIZED TO VIEW THIS PAGE");
        this.$router.push({ path: `/car-rental-seremban` });
      }
    },

    uploadImage(file) {
      if (file) {
        let maxId = 0;
        this.$setLoader();
        for (let image of this.car.imageUrl) {
          if (image.id > maxId) {
            maxId = image.id;
          }
        }
        let newId = maxId + 1; // Increment the maximum id for the new image
        file.resource_type = "image";
        file.url = process.env.VUE_APP_CLOUDINARY_URL_IMAGE;
        this.uploadFileToCloudinary(file).then((fileResponse) => {
          // Build the Cloudinary URL with transformation parameters
          const url = `https://res.cloudinary.com/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/image/upload/c_fill,f_auto,q_auto/${fileResponse.public_id}.${fileResponse.format}`;

          this.car.imageUrl.push({ url: url, id: newId });
          this.$disableLoader();
        });
      }
    },

    uploadFileToCloudinary(file) {
      return new Promise(function (resolve, reject) {
        let formData = new FormData();
        formData.append(
          "upload_preset",
          process.env.VUE_APP_CLOUDINARY_PRESET1
        );
        formData.append("resource_type", file.resource_type);
        formData.append("file", file);
        let request = new XMLHttpRequest();
        request.open(
          "POST",
          `https://api.cloudinary.com/v1_1/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/upload`,
          true
        );
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        request.onreadystatechange = () => {
          if (request.readyState === 4 && request.status === 200) {
            let response = JSON.parse(request.responseText);
            resolve(response);
          }
          if (request.status !== 200) {
            let response = JSON.parse(request.responseText);
            let error = response.error.message;
            this.errorText = "error uploading files " + error;
            this.isError = true;
            reject(error);
          }
        };
        request.onerror = (err) => {
          alert("error: " + err);
          reject(err);
        };
        request.send(formData);
      });
    },

    removeUrl(url) {
      const index = this.car.imageUrl.findIndex((item) => item.id === url.id);
      if (index !== -1) {
        this.car.imageUrl.splice(index, 1);
      }
    },

    async update() {
      this.$setLoader();
      await dataService
        .updateCar({ _id: this.$route.params.cardId, car: this.car })
        .then((res) => {
          // this.car = res.data.car[0];
          console.log(res);
          window.location.reload();
        });

      this.$disableLoader();
    },
  },
};
</script>

<style scoped lang="css">
.container {
  /* margin-top: 5%; */
  max-width: none !important;
}

#particles-js {
  background-color: #f9ece0 !important;
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 0 !important;
}
</style>
